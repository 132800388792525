<template>
  <app-module-view>
    <template v-slot:navigation>
      <right-now-navigation/>
    </template>

    <template v-slot:body>
      <div class="card right-now-filter">
        <RightNowFilter/>
      </div>

      <Preloader v-if="callingAPI"/>

      <NewsOfTheDayTable
        :items="rightNow"
        :store-name="'rightNow'"
        :totalCount="totalCount"
        :current-page="page"
        :on-page-change="setPageAndGetRecords"
      />
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import NewsOfTheDayTable from '@/components/table/NewsOfTheDayTable'
import appModuleView from '@/components/ModuleView'
import RightNowNavigation from '@/views/rightNow/components/RightNowNavigation'
import RightNowFilter from '@/components/filter/FilterRightNow.vue'

export default {
  name: 'NewsOfTheDayListView',
  components: {
    RightNowFilter,
    RightNowNavigation,
    appModuleView,
    Preloader,
    NewsOfTheDayTable
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    rightNow () {
      return this.$store.getters['rightNow/list']
    },
    totalCount () {
      return this.$store.getters['rightNow/totalCount']
    },
    page () {
      return this.$store.getters['rightNow/page']
    }
  },
  mounted () {
    this.getRightNow()
  },
  methods: {
    async getRightNow () {
      await this.$store.dispatch('rightNow/fetch')
      this.dataLoaded = true
    },
    setPageAndGetRecords (page) {
      this.$store.commit('rightNow/setPage', page)
      this.getRightNow()
    }
  }
}
</script>
<style lang="scss" scoped>
.right-now-filter {
  padding: rem(30px);
}
</style>
