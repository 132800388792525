<template>
  <AbstractFilter
    :on-filter="filterRightNow"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    filter-panel-class="filter-panel-right-now"
    create-new-route-name="right_now_new"
  >
    <Select
      v-model="filter.siteId"
      :options="sites"
      :label="$t('rightNow.site')"
      id="filter_siteId"
    />
    <Input
      v-model="filter.titleOrText"
      id="filter_titleOrText"
      :label="$t('rightNow.titleOrText')"
    />
    <Datepicker
      type="date"
      v-model="filter.publishedAt"
      id="filter_publishedAt"
      :label="$t('rightNow.published_at')"
    />
    <Datepicker
      type="date"
      v-model="filter.modifiedAt"
      id="filter_modifiedAt"
      :label="$t('rightNow.modified_at')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import AbstractFilter from '@/components/filter/AbstractFilter'
import Datepicker from '@/components/form/Datepicker'
import PermissionService from '@/services/PermissionService'
import Select from '@/components/form/select/Select.vue'
import { MODULE_MODULES_RIGHT_NOW } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'RightNowFilter',
  components: {
    Select,
    Input,
    Datepicker,
    AbstractFilter
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS
    }
  },
  computed: {
    filter () {
      return this.$store.getters['rightNow/filter']
    },
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_RIGHT_NOW)
    }
  },
  methods: {
    filterRightNow () {
      this.$store.commit('rightNow/setPage', 1)
      this.$store.dispatch('rightNow/fetch')
    },
    resetFilter () {
      this.$store.commit('rightNow/resetFilter', this.filter)
      this.$store.dispatch('rightNow/fetch')
    }
  }
}
</script>

<style lang="scss">
.filter-panel-right-now {
  @include bp(7) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include bp(12) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
